import React from 'react';
import { ParagraphColumns } from '../../../generated/types';
import useIsMobile from '../../../hooks/useIsMobile';
import { default as BaseContainer } from '../../Layout/Container';
import PARAGRAPH_MAPPING from '../../../config/paragraph-mapping';
import { Box, Stack } from '@chakra-ui/react';

export interface ColumnsProps {
  data: ParagraphColumns;
}

const Columns: React.FC<ColumnsProps> = ({ data }) => {
  const isMobile = useIsMobile();

  return (
    <BaseContainer className="ParagraphColumns" px={0}>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={8}
        className="ParagraphColumns"
      >
        {data.content?.map((data) => {
          if (PARAGRAPH_MAPPING[data.__typename]) {
            const Component = PARAGRAPH_MAPPING[data.__typename];

            return (
              <Box flex={1} key={data.id}>
                <Component data={data} />
              </Box>
            );
          }

          return null;
        })}
      </Stack>
    </BaseContainer>
  );
};

export default Columns;
